body {
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  min-height: calc(100% + env(safe-area-inset-top));
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);

  font-size: calc(var(--font-size-base) * 1px) !important;
  color: var(--text2) !important;
  background-color: var(--background);
  position: relative;
  overflow: auto;
}

.landscape__wrapper {
  display: none;
  position: absolute;
  z-index: 100000;
  width: 100%;
  height: var(--app-height);
  background-color: #fff;
  .landscape__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    img {
      width: 100px;
      height: 100px;
    }
    .landscape__content__text {
      font-size: 16px;
      font-weight: 500;
      color: var(--text1);
      text-align: center;
      margin-top: 20px;
    }
  }
}

@media screen and (max-height: 500px) and (orientation: landscape) {
  html {
    overflow: hidden;
    height: var(--app-height);
    pointer-events: none;
    .landscape__wrapper {
      display: block;
    }
  }
}

code {
  font-family:
    source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

span,
p,
h1,
h2,
h3,
h4,
h5 {
  font-family: 'Poppins', sans-serif;
}

body {
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;

  --font-size-base: 15;

  --app-height: 100vh;
  --app-height: calc(var(--vh, 1vh) * 100);

  --rsbs-backdrop-bg: rgba(0, 0, 0, 0.6);
  --rsbs-bg: var(--background, #fff);
  --rsbs-handle-bg: var(--secondary, #000);
}

img {
  max-height: 100%;
  max-width: 100%;
}

.rdw-option-wrapper img {
  max-height: max-content;
  max-width: max-content;
}

.ant-tabs.ant-tabs-top {
  background-color: var(--background, #fff);
}

.bmpui-ui-watermark {
  display: none;
}

textarea {
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: var(--text3) !important;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: var(--text3) !important;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: var(--text3) !important;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: var(--text3) !important;
  }
}
