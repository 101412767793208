// ------Post list
.postLists {
  width: 100%;
  align-items: stretch;
  margin: 0 auto;
}

.creatorPostsWrapper {
  background-color: var(--background);
  margin: 12px auto;
  width: 100%;
  position: relative;
  overflow: hidden;

  &__repost-card {
    margin: 12px 12px 4px;
    // padding: 12px;
    border: 1px solid var(--border);
    border-radius: 6px;
    overflow: hidden;
  }

  &.feedPost {
    margin: 0 auto;
    margin-block: 6px;
  }

  .pinnedPost {
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid var(--gray_light);

    span {
      font-size: 14px;
      color: var(--icon) !important;
      font-weight: 500;
      margin-left: 6px;
    }
  }
}

// ------Post item

.creatorPostHeader {
  display: flex;
  align-items: center;
  padding: 12px 12px 3px;
  .siteBtn {
    margin-left: auto;
    align-self: flex-start;
  }
  .ant-avatar {
    flex-shrink: 0;
  }
  .ant-avatar,
  .postCreator {
    cursor: pointer;
  }
}

.postHeaderDetailsWrapper {
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  width: calc(100% - 100px);
}

.postCreator {
  font-size: 16px;
  color: var(--text1) !important;
  font-weight: 600;
}

.postHeaderDetails {
  display: flex;
  align-items: center;
  * {
    font-weight: 500;
    font-size: 14px;
    color: var(--text3) !important;
  }
  > * + * {
    margin-left: 5px;
  }
}

.postMangoesWrapper {
  font-weight: 500;
  font-size: 14px;
  color: var(--text3) !important;
  display: inline-flex;
  flex: 0 1 auto;
  // width: calc(100% - 45px);
  width: 100%;
}

.creatorPostContentWrapper {
  padding: 12px 12px 3px;
}

.postContent {
  color: var(--text2) !important;
  font-size: 14px;
  margin: 0 !important;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  white-space: pre-wrap;

  a {
    color: var(--link) !important;
  }
}

.creatorPostMediaWrapper {
  margin: 12px 0px 3px;
  background-color: rgba(0, 0, 0, 0.78);
  .postImageWrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: auto;
    z-index: 1;
    cursor: zoom-in;
    background-color: rgba(0, 0, 0, 0.78);
    display: grid;
    .mainImage {
      width: 100%;
      height: 100%;
      object-fit: contain;
      display: block;
    }
    .blurImg {
      transform: translate3d(0, 0, 0) translateZ(0);
      perspective: 1000px;
      backface-visibility: hidden;
      -moz-transform: translate3d(0, 0, 0) translateZ(0);
      -moz-perspective: 1000px;
      -moz-backface-visibility: hidden;
      -webkit-transform: translate3d(0, 0, 0) translateZ(0);
      -webkit-perspective: 1000px;
      -webkit-backface-visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      opacity: 0.4;
      filter: blur(5px);
      -webkit-filter: blur(5px);
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: block;
        background: rgba(0, 0, 0, 0.5);
      }
      > img {
        width: 100%;
        display: block;
        height: 100%;
      }
    }

    @media (max-width: 600px) {
      .mainImage {
        width: 100vw;
        height: 100vw;
        object-fit: contain;
        display: block;
      }
    }

    @media (min-width: 600px) {
      .mainImage {
        height: 465px;
      }
    }
  }
  .postVideoWrapper {
    max-width: 700px;
    margin: 0 auto;
  }
}

.postStats {
  .postStatsTextWrapper {
    padding: 6px 12px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-bottom: 1px solid var(--border);
    ._no {
      color: var(--icon) !important;
      font-size: 13px;
      font-weight: 500;
      &.separator {
        margin: 0 5px;
      }
    }
  }
  .postStatsWrapper {
    padding: 12px;
    display: flex;
    align-items: center;
    gap: 16px;

    > * {
      // padding: 12px;
      display: inline-flex;
      align-items: center;
    }

    .svgLike {
      fill: white;
      stroke: var(--text2);
      strokewidth: 2px;
      transition: all 0.15s ease;

      &.active {
        fill: var(--danger);
        stroke: var(--danger);
      }
    }

    .postWhatsAppWrapper {
      border-color: #1bd741;
    }

    .postShareWrapper {
      margin-left: auto;
      ._no {
        font-weight: 500;
      }
    }

    .heart {
      -webkit-appearance: none;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      cursor: pointer;
      svg:first-child {
        position: absolute;
        transform: scale(1);
        transition: all 0.5s ease;
      }
      svg:last-child {
        position: relative;
        z-index: 1;
        opacity: 0;
        transform: scale(0);
        transition: all 0.5s ease;
      }
      &.active {
        svg:first-child {
          transform: scale(0);
        }
        svg:last-child {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }
}

.postShareOptionsWrapper {
  display: flex;
  align-items: center;
  > * + * {
    margin-left: 10px;
  }
}

.wpStatsWrapper {
  display: flex;
  align-items: center;
  .wpStatsWrapperItem {
    display: flex;
    align-items: center;
    .wpStatsNo {
      font-size: 13px;
      font-weight: 600;
      color: var(--text2);
      padding-left: 5px;
    }
  }
  > * + * {
    margin-left: 10px;
  }
}

// +++++++++++++++++++++++++ Remove Post Modal +++++++++++++++++++++ //
.removePostModalBody {
  padding: 12px 0;
  .text {
    font-size: 15px;
    color: var(--text3) !important;
    font-weight: 500;
  }
}

.removePostModalFooter {
  display: flex;
  justify-content: flex-end;
  padding: 12px 0;
}

.creatorPostsWrapper {
  .postDetailsCommentInputWrapper {
    padding: 0 12px 12px;
  }
  .nestedCommentStat {
    width: 100%;
    text-align: left;
  }
  .commentStat,
  .nestedCommentStat {
    display: flex;
    align-items: center;
    justify-content: space-between;
    span:not(.commentNumber) {
      color: var(--text1);
      font-weight: 500;
      font-size: 14.5px;
      text-decoration: underline;
      cursor: pointer;
    }
    .commentNumber {
      color: gray;
      font-size: 12px !important;
    }
  }
  .postCommentInputWrapper .postCommentInput {
    border: 1.5px solid #ebedf2;
    background: #f2f3f7;
    color: #a6a6b2;
    border-radius: 25px;
    box-shadow: inset 1px 3px 5px rgba(0, 0, 0, 0.05);
    margin: 0 0 0 10px;
  }
  .postComments .postComment .nestedComment {
    width: 100% !important;
  }
  .replyCountBtn {
    span {
      text-decoration: underline;
    }
  }
  .postCommentInputWrapper {
    padding: 0 0 5px;
  }
  .postComments .postComment .postCommentDetails > div .commentLike {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    > span {
      margin-top: 3px;
      color: #212121 !important;
    }
  }
}

// +++++++++++++++++++++++ Post Comment v2 ++++++++++++++++++++++++++ //
.postCommentv2Wrapper {
  padding: 0 12px 6px;
}

.postSeparator {
  margin: 0;
  border-top: 1px solid #e4e6eb;
  margin: 0 auto 6px;
}

.postInputv2__reply {
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  background-color: var(--gray_lighter);
  .postInputv2__reply__content {
    display: flex;
    flex-direction: column;
    width: calc(100% - 40px);
    span {
      font-size: 11px;
      color: var(--text2);
      font-weight: 500;
      margin: 0;
    }
    h5 {
      font-size: 13px;
      color: var(--text1);
      font-weight: 600;
      margin: 0;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -8px -12px;
    padding: 8px 8px 8px 12px;
    height: 50px;
    outline: 0;
    border: none;
    background-color: transparent !important;
  }
}

.postInputv2__mentions {
  display: flex;
  flex-direction: column;
  > div {
    position: static !important;
    margin-top: 0 !important;
  }
  .mention-suggestion {
    padding: 8px 12px;
    // border-bottom: 1px solid var(--border);

    &__name {
      font-size: 14px;
      color: var(--text1);
    }
  }
}

.postInputv2Container {
  border-top: 1px solid var(--border);
  box-shadow: 0 -1px 5px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 100;

  .postInputv2Wrapper {
    padding: 12px;
    display: flex;
    align-items: flex-end;

    .ant-avatar {
      flex-shrink: 0;
    }

    .postInputv2__sendIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      background-color: var(--primary);
      border-radius: 50%;
      flex-shrink: 0;
      padding: 0;
      border: none;
      margin-left: 12px;

      .ant-spin-dot-item {
        background-color: var(--background);
      }
    }
  }

  .post-input-wrapper__actions {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    gap: 12px;
    height: 36px;
    margin-left: 12px;

    &__icon-btn {
      flex-shrink: 0;
      cursor: pointer;
    }

    &__sending-loader {
      .ant-spin-dot {
        .ant-spin-dot-item {
          background-color: var(--icon);
        }
      }
    }
  }

  .post-input-file-preview {
    display: inline-flex;
    gap: 8px;
    padding-block: 8px;
    margin-left: 60px;
    width: max-content;
  }
}

.postInputv2 {
  margin-left: 12px !important;
  border-radius: 18px !important;
  // height: 36px !important;
  width: calc(100% - 112px);
  min-height: 36px !important;
  background-color: var(--gray_lighter) !important;
  border: 0 !important;
  // box-shadow: inset 1px 3px 5px rgba(0, 0, 0, 0.03) !important;
  outline: 0 !important;
  color: var(--text2) !important;
  max-height: 150px;
  overflow-y: auto;
  // &:focus,
  // &.ant-input-focused {
  //   outline: 0 !important;
  //   box-shadow: inset 1px 3px 5px rgba(0, 0, 0, 0.03) !important;
  // }
  textarea {
    background: transparent !important;
    border: none !important;
    outline: 0 !important;
    padding: 6px 12px !important;
    height: 36px;
    overflow: auto !important;
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: var(--text3) !important;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: var(--text3) !important;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: var(--text3) !important;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: var(--text3) !important;
    }
  }
}

.postCommentListWrapper {
  display: flex;
  flex-direction: column;
  padding: 12px;
}

.postReplyListWrapper {
  display: flex;
  flex-direction: column;
  padding-left: 46px;
}

.postCommentList,
.postReplyist {
  margin: 0;
  padding: 0;
  list-style-type: none;
  > * + * {
    margin-top: 10px;
  }
}

.postReplyist {
  padding: 6px 0 0;
}

.postCommentListItem,
.postReplyListItem {
  display: flex;
  position: relative;
  flex-direction: column;
  > span {
    display: flex;
    position: relative;
  }

  .ant-avatar,
  .siteBtn.circularBgHover {
    flex-shrink: 0;
  }

  .siteBtn.circularBgHover {
    align-self: center;
  }
}

.postCommentContainer {
  padding: 8px;
  display: inline-flex;
  flex-direction: column;
  background-color: var(--gray_lighter);
  border-radius: 12px;
  margin: 0 8px;
  position: relative;
  min-width: 175px;
  transition: background-color 0.3s ease-in-out;
  &.selected {
    background-color: var(--primary_light);
  }
  &.blink {
    -webkit-animation: blink-1 1s ease-in 500ms alternate forwards;
    animation: blink-1 1s ease-in 500ms alternate forwards;
  }
}

@-webkit-keyframes blink-1 {
  0%,
  50%,
  100% {
    background-color: var(--primary_light);
  }
  25%,
  75% {
    background-color: var(--gray_lighter);
  }
}
@keyframes blink-1 {
  0%,
  50%,
  100% {
    background-color: var(--primary_light);
  }
  25%,
  75% {
    background-color: var(--gray_lighter);
  }
}

.postLikeCount {
  display: inline-flex;
  align-items: center;
  background: white;
  border-radius: 10px;
  padding: 2px 6px 1px;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.025em;
  box-shadow: 0 1px 3px 0 rgba($color: #000000, $alpha: 0.2);
  position: absolute;
  right: 2px;
  bottom: -10px;
  img,
  svg {
    display: block;
    max-width: 100%;
    margin-right: 4px;
  }
}

.commentAuthor {
  font-size: 13px;
  font-weight: 600;
  color: var(--text1) !important;
  margin-bottom: 2px;
}

.postCommentText {
  color: var(--text2) !important;
  font-size: 14px;
  font-weight: 400;
  margin: 0 !important;

  a {
    color: var(--link) !important;
  }

  .ant-typography-expand {
    display: table;
    margin-left: 0;
    color: var(--link);
    font-weight: 600;
    // padding-top: 5px;
    position: relative;
    font-size: 14px;
    z-index: 1;
    // text-decoration: underline;

    // &:focus,
    // &:hover {
    //   color: var(--darkblue);
    //   text-decoration: underline;
    // }
  }
}

.postCommentAction {
  display: flex;
  align-items: center;
  padding: 2px 0 6px 40px;

  > * + * {
    padding-left: 2.5px;
    margin-left: 2.5px;
  }

  .circularSeperator {
    font-size: 8px;
    color: var(--text3) !important;
  }

  .postCommentTime {
    font-size: 12px;
    color: var(--text3) !important;
    font-weight: 500;
    transition: all 0.25s ease;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .siteBtn.siteBtnLink.dark {
    color: var(--text3) !important;
    text-decoration: none !important;
    span {
      font-size: 12px;
      font-weight: 600;
      padding: 0;
    }
    &:hover,
    &:focus {
      color: var(--text3) !important;
    }
    &.like {
      transition: all 0.25s ease;
      &.active {
        color: #e3254d !important;
      }
    }
  }
}

.viewMoreCommentWrapper,
.writeCommentWrapper {
  padding: 6px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .siteBtn.dark {
    text-decoration: none !important;
    color: var(--text3) !important;
    transition: all 0.25s ease;
    span {
      padding: 0;
      font-size: 14px;
      font-weight: 500;
    }
    &:hover,
    &:focus {
      color: var(--text3) !important;
      text-decoration: underline !important;
    }
  }
  .commentCountDetails {
    color: var(--text3);
    word-break: break-word;
    font-size: 0.875rem;
    word-wrap: break-word;
  }
}

.postCommentReplyCount {
  display: flex;
  align-items: center;
  padding-left: 40px;
  img,
  svg {
    margin-right: 4px;
  }
  .replyCount {
    color: var(--text3) !important;
    font-weight: 600;
    font-size: 12px;
  }
}

.postTooltip {
  max-width: 300px;
}

.lockedContent {
  width: 100%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background: #000000;

  .lockedContentInner {
    text-align: center;
    width: 100%;
    max-width: 450px;
    padding: 45px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .siteBtn {
    margin-top: 25px;
  }

  .loackedContentTitle {
    color: white;
    margin-bottom: 10px !important;
  }

  .loackedContentText {
    color: rgba($color: white, $alpha: 0.65);
  }

  img {
    width: 35px;
    filter: invert(100%);
    margin-bottom: 35px;
  }
}

.postDetailsModal {
  margin: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100% !important;
  height: 100% !important;
  max-width: 100%;
  .ant-modal-body {
    padding: 0;
  }
}

.post-tags {
  display: flex;
  margin: 12px 12px 0;
  width: calc(100% - 24px);
  align-items: center;

  .post-tag-item {
    margin-right: 6px;
    padding: 4px 8px;
    border-radius: 50px;
    font-size: 12px;
    font-weight: 500;
    color: var(--text2);
    // color: var(--background);
    min-width: 20px;
    background: var(--tertiary);
    // background-color: var(--primary);

    &.more {
      min-width: max-content;
      cursor: pointer;
    }

    .post-tag-item-text {
      color: var(--text2);
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
  }
}

.tagSelectionModal {
  .tagSelectionModalItem {
    margin: 12px;
    padding: 6px 12px;
    border-radius: 6px;
    border: 1px solid var(--border);
    span {
      font-size: 14px;
      font-weight: 500;
      color: var(--text3);
    }
  }
}
