.upcomingWorkshop {
  .grayBtn {
    background-color: var(--gray_lighter) !important;
  }
  .ant-result {
    .noDataFoundTitleWrapper {
      width: 100%;
    }
    @media screen and (max-width: 767px) {
      .ant-result-icon img {
        width: 48px !important;
      }
      padding: 24px;
    }
  }
}
.workshopCardOuter {
  border: 1px solid var(--border);
  border-radius: 12px;
  width: 100%;
  padding: 0 12px;
  position: relative;
  background-color: var(--background);

  .workshopCardImage {
    width: 100%;
    aspect-ratio: 16/9;
    margin-top: 12px;
    border-radius: 6px;
    object-fit: cover;
  }
  .moreBtn {
    position: absolute;
    top: 4px;
    right: 4px;
    z-index: 1;
  }
  .workshopCard {
    padding: 12px 0;
    display: flex;
    position: relative;
  }
  .workshopCardDate {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: flex-start;
    border-radius: 8px;
    background-color: var(--background);
    width: 45px;
    flex-shrink: 0;
    box-shadow: 0 0px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    .month {
      height: 16px;
      text-align: center;
      background-color: var(--blue);
      border-radius: 8px 8px 0 0;
      font-size: 10px;
      font-weight: 600;
      color: var(--background);
      width: 100%;
    }
    .date {
      font-size: 17px;
      font-weight: 700;
      color: var(--text1);
    }
    .day {
      font-size: 10px;
      font-weight: 600;
      color: var(--darkblue);
      padding: 1px 2px 2px;
      line-height: 1.65;
      border-top: 1px dotted var(--secondary);
    }
  }
  .workshopCardDetails {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    width: calc(100% - 64px);
    .time {
      font-size: 12px;
      font-weight: 600;
      color: var(--blue);
      text-transform: uppercase;
    }
    .workshopTitle {
      font-size: 15px;
      font-weight: 500;
      color: var(--text1);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
      // margin-top: 2px;
    }
    .host {
      font-size: 12px;
      color: var(--text2);
      overflow: hidden;
      text-overflow: ellipsis;
      .hostName {
        font-weight: 500;
        color: var(--text1);
        margin-left: 4px;

        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .workshopCardAction {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 0;
    border-top: 1px solid var(--border);
    .joinBtn {
      height: 32px !important;
      span {
        margin-left: 8px;
        font-size: 13px;
      }
    }
    .timer {
      font-size: 13px;
      font-weight: 500;
      color: var(--text2);
      display: flex;
      align-items: center;
      strong {
        color: var(--text1);
        margin-left: 6px;
        font-weight: 500;
      }
    }
  }
  &.completed {
    .workshopCardDate {
      .month {
        background-color: var(--danger);
      }
    }
    .workshopCardDetails {
      .time {
        color: var(--text4);
      }
    }
  }
}

.workshopCardFeed {
  padding: 12px;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  gap: 12px;
  .workshopCardDetails {
    display: flex;
    flex-direction: column;
    .time {
      font-size: 10px;
      font-weight: 400;
      color: var(--text3);
      text-transform: uppercase;
      margin-top: 6px;
    }
    .workshopTitle {
      font-size: 15px;
      font-weight: 600;
      color: var(--text1);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
      // margin-top: 2px;
    }
    .host {
      font-size: 12px;
      color: var(--text2);
      overflow: hidden;
      text-overflow: ellipsis;
      .hostName {
        font-weight: 500;
        color: var(--text1);
        margin-left: 4px;

        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .workshopCardAction {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 12px;
    border-top: 1px solid var(--border);
    .joinBtn {
      height: 32px !important;
      span {
        margin-left: 8px;
        font-size: 13px;
      }
    }
    .timer {
      font-size: 13px;
      font-weight: 400;
      color: var(--text2);
      display: flex;
      align-items: center;
      strong {
        color: var(--text1);
        margin-left: 6px;
        font-weight: 600;
      }
    }
  }
}

// ++++++++++++ Tooltip ++++++++++++++ //
.workspaceTooltip {
  max-width: 275px;
  .ant-tooltip-inner {
    min-height: 0;
    font-size: 12px;
    padding: 4px 10px;
    text-align: center;
    border-radius: 6px;
  }
}

// ++++++++++++ Workshop Modal ++++++++++++++ //
.workshopDetailsModal {
  .ant-modal-content {
    border-radius: 12px;
  }
  .ant-modal-body {
    padding: 0;
    border-radius: 12px 12px 0 0;
    overflow: hidden;
    height: 100%;
    max-height: calc(var(--app-height) - 120px);
    transition: all 0.3s ease-in;
    overflow-y: auto;
    position: relative;

    &::-webkit-scrollbar-track {
      // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 0px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      transition: all 0.3s ease-in;
    }

    &::-webkit-scrollbar-thumb:horizontal {
      background-color: transparent;
      transition: all 0.3s ease-in;
    }

    &:hover {
      &::-webkit-scrollbar-thumb:horizontal,
      &::-webkit-scrollbar-thumb {
        background-color: transparent;
        transition: all 0.3s ease-in;
      }
    }
  }
  .workshopModalCover {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 45%;
    border-radius: 12px 12px 0 0;
    img {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }
  .workshopModalBody {
    padding: 12px;
    max-height: 100%;
    .workModalTitle {
      font-size: 20px;
      line-height: 26px;
      color: var(--text1);
      margin: 0;
      font-weight: 600;
      margin-right: 30px;
      span {
        display: block;
        color: var(--text2);
        font-weight: 400;
        font-size: 13px;
        line-height: 1;
        margin-top: 6px;
        strong {
          font-weight: 500;
          color: var(--text1);
          margin-left: 4px;
        }
      }
    }
    .workshopModalTimeWrapper {
      border: 1px solid var(--secondary);
      border-radius: 6px;
      margin: 12px auto;
      padding: 8px 8px 10px 0;
      display: flex;
      .dateWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 12px;
        flex-shrink: 0;
        border-right: 1px solid var(--secondary);
        .month {
          font-size: 12px;
          font-weight: 600;
          color: var(--blue);
          letter-spacing: 0.25px;
        }
        .date {
          font-size: 20px;
          font-weight: 500;
          color: var(--text1);
          letter-spacing: 1px;
          line-height: 1;
        }
      }
      .workshopModalTimeDetails {
        display: flex;
        flex-direction: column;
        padding-left: 12px;
      }
      .day {
        font-size: 14px;
        font-weight: 500;
        color: var(--text1);
      }
      .time {
        font-size: 12px;
        color: var(--text2);
        font-weight: 500;
        margin-top: 2px;
      }
      .blueBtnV2 {
        margin-left: auto;
        align-self: center;
        background-color: transparent !important;
        height: 32px !important;
        span {
          font-size: 12px;
          text-transform: uppercase;
          letter-spacing: 0.025em;
        }
        &:hover {
          background-color: #e7f3ff !important;
          filter: brightness(100%);
        }
      }

      .calendarBtn {
        margin-left: auto;
        align-self: center;
        background-color: #e7f3ff !important;
        height: 46px !important;
        width: 46px !important;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .descriptionTitle {
      font-size: 16px;
      font-weight: 600;
      color: var(--text1);
      margin-bottom: 6px;
    }
    .workshopModalDescription {
      font-size: 14px;
      color: var(--text1) !important;
      line-height: 1.35;
      * {
        font-size: 14px;
      }
      b,
      strong {
        font-weight: 600;
      }
      a {
        color: var(--blue);
        font-weight: 500;
      }
      .ant-typography-expand {
        color: var(--text1) !important;
        font-weight: 600;
        text-decoration: underline;
      }
    }
  }
  .ant-modal-footer {
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.0425);
    border-radius: 0 0 12px 12px;
    text-align: center;
    padding: 12px;
    // padding: 12px 16px 16px;
    background: var(--background);
  }
  .workshopModalFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
    .workshopModalInfoText {
      display: flex;
      align-items: center;
      color: var(--text1);
      font-size: 11px;
      margin-bottom: 8px;
    }
    .workshopModalTimer {
      display: flex;
      align-items: center;
      font-size: 13px;
      font-weight: 500;
      color: var(--text2);
      margin-bottom: 12px;
      strong {
        color: var(--text1);
        font-weight: 500;
        margin-left: 6px;
      }
    }
    .workshopModalFooterBtnGroup {
      display: flex;
      width: 100%;
      .blueBtnV2 {
        height: 36px !important;
      }
      .joinBtn span {
        margin-left: 8px;
        font-size: 13px;
      }
      .moreBtnTrigger {
        width: 36px !important;
        flex-shrink: 0;
        padding: 0 !important;
        margin-left: 8px;
        img,
        svg {
          transition: transform 0.15s ease-in;
        }
        &.active {
          filter: brightness(90%);
          img,
          svg {
            transform: rotate(180deg);
            transition: transform 0.15s ease-out;
          }
        }
      }
      > Button + Button {
        margin-left: 10px;
      }
    }

    .moreBtnGroup {
      display: flex;
      width: 100%;
      height: 0;
      opacity: 0;
      visibility: hidden;
      transition: all 0.15s ease-in;
      align-items: flex-end;
      .grayBtn {
        background-color: transparent !important;
        span {
          color: var(--text1);
        }
        &:hover {
          background-color: var(--gray_lighter) !important;
          filter: brightness(100%);
        }
      }
      &.active {
        height: 46px;
        opacity: 1;
        visibility: visible;
        transition: all 0.15s ease-out;
      }
    }
  }
}

.workshopAttendanceStatus {
  padding: 6px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  gap: 6px;

  span {
    font-size: 12px;
    font-weight: 400;
    color: var(--green);
  }
}
