.mango__selection__modal {
  [data-rsbs-header] {
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .mango__selection__modal__title {
    font-size: 16px;
    font-weight: 600;
    color: var(--text1);
    margin-bottom: 0px;
  }
  .mango__selection__modal__description {
    font-size: 14px;
    font-weight: 400;
    color: var(--text2);
    margin-bottom: 0px;
  }

  .mango__selection__modal__content__wrapper {
    padding: 12px;
  }

  .mangoSelectorCard {
    margin-top: 12px;
    border: 1px solid var(--border);
    border-radius: 4px;
    padding: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    .mangoSelectorCard__name {
      font-size: 14px;
      font-weight: 400;
      color: var(--text1);
    }
    .mangoSelectorCard__button {
      height: 24px;
    }
  }
}

.mango_select_all_wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px;
  cursor: pointer;
  .mango_select_all {
    background-color: transparent;
    border: none;
    svg {
      font-size: 19px;
      position: relative;
      top: 5px;
      margin-left: 5px;
    }
  }
  .selected_all_mango {
    color: var(--primary);
    svg {
      fill: var(--primary);
    }
  }
}
