.qnaRepliesModal {
  padding-bottom: 0 !important;
  max-height: 100%;
  top: 12px;
  bottom: 12px;

  .ant-modal-content {
    height: calc(var(--app-height) - 36px);
    display: flex;
    flex-direction: column;
    border-radius: 12px !important;
    box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
      inset 0 0 0 1px rgba(255, 255, 255, 0.5) !important;
  }

  .ant-modal-header {
    border-radius: 12px 12px 0 0;
    padding: 0px;
    border: none;
    .default-header {
      border-radius: 12px 12px 0 0;
    }
  }

  .ant-modal-body {
    padding: 0px;
    border-radius: 0px;
    overflow: hidden;
    height: 100%;
    max-height: calc(var(--app-height) - 145px);
    transition: all 0.3s ease-in;
    overflow-y: auto;
    position: relative;
    background-color: var(--page_bg);

    &::-webkit-scrollbar-track {
      // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 0px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      transition: all 0.3s ease-in;
    }

    &::-webkit-scrollbar-thumb:horizontal {
      background-color: transparent;
      transition: all 0.3s ease-in;
    }

    &:hover {
      &::-webkit-scrollbar-thumb:horizontal,
      &::-webkit-scrollbar-thumb {
        background-color: transparent;
        transition: all 0.3s ease-in;
      }
    }
  }
  .ant-avatar-circle {
    flex-shrink: 0;
  }
  .replyCount {
    padding: 12px;
    background-color: var(--background);
    // padding-left: 53px;
    .replyCountText {
      font-size: 14px;
      font-weight: 500;
      color: var(--text3);
      margin: 0;
    }
  }
}

.replyInputBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  .uploadWrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, calc(calc(100% / 4) - 9px));
    gap: 12px;
    margin-bottom: 12px;

    .uploadWrapper__imageWrapper {
      background-color: var(--tertiary);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      position: relative;
      width: 100%;
      aspect-ratio: 4/3;
      border: 1px solid var(--border);
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
      }
      .uploadWrapper__imageWrapper__removeIcon {
        position: absolute;
        top: -5px;
        right: -5px;
        svg {
          height: 20px;
          width: 20px;
        }
      }
    }
  }
  .replyInputBox__content {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    .replyInputBox__content__inputWrapper {
      display: flex;
      align-items: flex-end;
      width: 100%;
      background-color: var(--gray_lighter);
      padding: 2.5px;
      padding-right: 12px;
      border-radius: 20px;
      margin-right: 12px;
      .replyInputBox__content__inputWrapper__addIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
        background-color: var(--primary);
        border-radius: 50%;
        flex-shrink: 0;
        padding: 0;
        border: none;
        margin-right: 8px;
      }

      #repliesFileUpload {
        display: none;
      }
      .replyInputBox__content__inputWrapper__input {
        width: 100%;
        border: none;
        background-color: transparent;
        font-size: 16px;
        color: var(--text1);
        font-weight: 400;
        line-height: 25px;
        min-height: 25px;
        padding: 5px 0;
        outline: 0;
        resize: none;
        max-height: 100px;
        overflow-y: auto;
        direction: ltr;
        text-align: left;
        word-break: break-word;
        &[placeholder]:empty:before {
          font-size: 16px;
          content: attr(placeholder);
          color: var(--text4);
        }
      }
    }
    .replyInputBox__content__sendIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      background-color: var(--primary);
      border-radius: 50%;
      flex-shrink: 0;
      padding: 0;
      border: none;
      .ant-spin-dot-item {
        background-color: var(--background);
      }
    }
  }
}
