.feed__container {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  .feed__content {
    height: 100%;
    overflow-y: auto;
    width: 100%;
  }

  .feed__banner {
    display: block;
    width: 100%;
    aspect-ratio: 21 / 9;
    // margin: 6px auto;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

// sticky-scroll
.sticky-scroll {
  position: sticky;
}

.transition {
  transition: top 350ms ease-in-out;
}

//feed
.feedCard {
  width: 100%;
  background-color: var(--background);
  margin: 6px auto;
  .feedCardHeader {
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .feedCardTitle {
      color: var(--text1);
      font-size: 1.2rem;
      margin: 0;
      font-weight: 600;
    }
  }
  .feedCardBody {
    padding: 0 12px 12px;
  }
  .feedCardFooter {
    padding: 12px;
  }
}

// +++++++++++++++++++++ No data Found Component +++++++++++++++++++++++ //
.noDataComponent {
  .ant-result-icon {
    margin-bottom: 16px;
  }

  .noDataFoundTitleWrapper {
    // padding: 0 65px;
    width: 75%;
    margin: 0 auto;
  }

  .noDataFoundTitle {
    font-weight: 500 !important;
    color: var(--text2) !important;
    font-size: 1rem !important;
    margin-bottom: 8px !important;
  }

  .noDataFoundSubtitle {
    font-size: 0.875rem;
    color: var(--text3);
    font-weight: 400;
    line-height: 1.45;
    display: block;
    margin-top: 4px;
  }
}

//---------  Mango Title

.postMangoesWrapper {
  font-weight: 500;
  font-size: 14px;
  color: var(--text3) !important;
  display: inline-flex;
  flex: 0 0 auto;
  // width: calc(100% - 45px);
  width: 100%;
}

//-------feed Poll

.feedPollSystem {
  padding: 12px 12px 0px;
  .feedPollSystemInner {
    padding: 12px;
    border: 1px solid var(--border);
    border-radius: 6px;
  }
  h1 {
    color: #050505 !important;
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  p {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #050505 !important;
    white-space: nowrap;
    padding: 0.64rem !important;
  }

  .pollOptionsWrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    > .siteBtn + .siteBtn {
      margin-top: 12px;
    }
    .pollOption {
      border: 2px solid var(--primary) !important;
      color: var(--primary) !important;
      border-radius: 38px !important;
      justify-content: center;
      padding: 4px 0 !important;
      &:hover {
        background-color: var(--primary_light) !important;
      }
    }
  }

  .pollAnswersWrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    > .pollAnswer + .pollAnswer {
      margin-top: 12px;
    }
    .pollAnswer {
      position: relative;
      overflow: hidden;
      border-radius: 6px;
      padding: 8px 12px;
      color: #050505;
      font-weight: 600;
      font-size: 14px;
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      .pollOptionText {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        z-index: 1;
      }
      .pollOptionPercentage {
        flex-shrink: 0;
        padding-left: 48px;
        z-index: 1;
      }
      .pollAnswerProgress {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 0;
        background-color: #f0f2f5;
        transition: width 0.3s ease;
        &.max {
          background-color: var(--primary_light);
        }
      }
    }
  }
}

// ------------ Post States
.wpStatsWrapper {
  display: flex;
  align-items: center;
  .wpStatsWrapperItem {
    display: flex;
    align-items: center;
    .wpStatsNo {
      font-size: 13px;
      font-weight: 600;
      color: var(--text2);
      padding-left: 5px;
    }
  }
  > * + * {
    margin-left: 10px;
  }
}
.servicesCard {
  padding-bottom: 12px;
  .feedCardBody {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-x: auto;
  }
  .feedServiceCard {
    flex-shrink: 0;
    border-radius: 6px;
    width: 80%;
    padding: 0 12px;
    position: relative;
    border: 1px solid var(--border);
    &:not(:last-child) {
      margin-right: 12px;
    }
    .serviceCardHeader {
      display: flex;
      align-items: center;
      padding: 12px 0;
      .serviceImg {
        position: relative;
        overflow: hidden;
        width: 45px;
        height: 35px;
        flex: 0 0 45px;
        margin: 0;
        padding-right: 8px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 2px;
        }
      }
      .serviceInfo {
        width: calc(100% - 53px);
        .serviceTitle {
          font-size: 15px;
          color: var(--text1);
          font-weight: 600;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          margin-bottom: 2px;
        }
        .serviceStartDate {
          font-size: 12px;
          color: var(--text3);
        }
      }
    }
    .serviceCardFooter {
      padding: 6px 0 10px;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      .discountedPrice {
        font-size: 17px;
        font-weight: 700;
        color: var(--text1);
        font-family: Arial, Helvetica, sans-serif !important;
        line-height: 1;
      }
      del {
        font-size: 12px;
        color: var(--text3);
        font-family: Arial, Helvetica, sans-serif !important;
        line-height: 1;
      }
      .discount {
        font-size: 12px;
        color: var(--green);
        line-height: 1;
      }
      .buyBtn {
        height: 32px !important;
        span {
          font-size: 13px !important;
        }
      }
    }
  }
}

// -------------- Feed Cards
.feed-card {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  width: 100%;
  // gap: 16px;
  // border-radius: 8px;
  margin-block: 6px;
  background-color: var(--background);
  overflow: hidden;

  &__icon-bar {
    // padding: 12px;
    width: 36px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    // border-radius: 7px 0 0 7px;
    svg {
      width: 20px;
      height: 20px;
    }
    &__text {
      font-size: 14px;
      font-weight: 600;
      color: var(--background);
      transform: rotate(-90deg);
      letter-spacing: 2px;
    }
  }

  &__details-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    padding: 12px;
    width: 100%;
    // border-radius: 0 6px 6px 0;
    &.compact {
      padding: 8px 12px;
    }
  }

  .feed-card__header {
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 2px;
  }

  .feed-card__title {
    font-weight: 00;
    font-size: 13px;
    color: var(--text1);

    b {
      font-weight: 500;
      color: var(--text1);
    }
  }

  .feed-card__subtitle {
    font-weight: 400;
    font-size: 10px;
    color: var(--text3);
  }

  .feed-card__arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: -8px;
  }

  &.primary {
    border-color: var(--primary);

    .feed-card__icon-bar {
      background-color: var(--primary);
    }

    .feed-card__header {
      color: var(--primary);
    }
  }

  &.info {
    border-color: var(--link);

    .feed-card__icon-bar {
      background-color: var(--link);
    }

    .feed-card__header {
      color: var(--link);
    }
  }

  &.success {
    background-color: #f2fcf5;
    border-color: var(--green);

    .feed-card__icon-bar {
      background-color: var(--green);
    }
  }

  &.danger {
    border-color: var(--danger);
    .feed-card__icon-bar {
      background-color: var(--danger);
    }
    .feed-card__header {
      color: var(--danger);

      & > h1,
      & > h2,
      & > h3,
      & > h4,
      & > h5,
      & > h6 {
        color: var(--danger);
      }
    }
  }

  &.warning {
    border-color: var(--warning);

    .feed-card__icon-bar {
      background-color: var(--warning);
    }
  }
}

.create-a-post-card {
  padding: 12px;
  background-color: var(--background);
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  gap: 12px;

  .ant-avatar {
    flex-shrink: 0;
  }

  .create-a-post-card__content {
    padding: 0 12px;
    height: 35px;
    width: 100%;
    display: flex;
    align-items: center;
    // border-radius: 6px;
    border-radius: 50px;
    background-color: var(--tertiary);
    font-size: 12px;

    span {
      color: var(--text3);
    }
  }
}
