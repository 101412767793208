.input-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  align-items: start;

  .ant-avatar {
    flex-shrink: 0;
  }

  .input-wrapper__actions {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    gap: 12px;
    height: 36px;
    margin-left: 12px;

    &__icon-btn {
      flex-shrink: 0;
      cursor: pointer;
    }

    // .circularBgHover {
    //   &:hover,
    //   &:focus {
    //     background-color: transparent !important;
    //   }
    // }
  }
}

.input-wrapper__input {
  margin-left: 8px !important;
  border-radius: 18px !important;
  // height: 36px !important;
  min-height: 36px !important;
  background-color: var(--gray_lighter) !important;
  border: 0 !important;
  // padding: 3px 5px !important;
  padding: 8px 16px !important;
  // box-shadow: inset 1px 3px 5px rgba(0, 0, 0, 0.03) !important;
  outline: 0 !important;
  font-weight: 400;
  font-size: 14px !important;
  color: var(--text-title-lighter) !important;
  max-height: 150px !important;
  overflow-y: auto !important;

  &:focus,
  &.ant-input-focused {
    outline: 0 !important;
    // box-shadow: inset 1px 3px 5px rgba(0, 0, 0, 0.03) !important;
  }
}

.file-previews-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;

  // .input-file-preview {
  //   display: inline-flex;
  //   gap: 8px;
  //   width: max-content;

  //   // img {
  //   //   width: 120px;
  //   //   aspect-ratio: auto 120 / 55;
  //   //   height: 55px;
  //   //   border-radius: 6px;
  //   // }
  // }
}
