.access-denied__container {
  height: var(--app-height);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  .access-denied__content {
    height: 100%;
    overflow-y: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .darkBtn {
      background-color: #000 !important;
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}
